const columns = [
    {
        title: '#',
        width:90,
        scopedSlots: { customRender: 'serial' }
    },
    {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '所属电梯类型',
        dataIndex: 'elevatorType',
        key: 'elevatorType',
        ellipsis: true,
        customRender: (text, row, index) => {
            return text==0 ? '客梯' : (text==1?'货梯':'扶梯');
        },
    },
    {
        title: '价格',
        dataIndex: 'price',
        key: 'price',
        ellipsis: true,
    },
    {
        title: '图片',
        dataIndex: 'imagesUrl',
        key: 'imagesUrl',
        ellipsis: true,
    },
    {
        title: '创建时间',
        key: 'createTime',
        dataIndex: 'createTime',
    },
    {
        title: '操作',
        key: 'operation',
        fixed: 'right',
        width: 140,
        scopedSlots: { customRender: 'action' },
    },
];
export {
    columns
}
