<template>
    <div class="container">
        <a-row>
            <a-form :layout="formLayout">
                <a-form-item
                        label="参数名称"
                >
                    <a-input placeholder="请输入" v-model="searchForm.name"/>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="search">查询</a-button>
                    <a-button style="margin-left: 20px" @click="reset">重置</a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row style="padding: 12px 0 0 12px">
            <a-button type="primary" @click="addOrEdite(null)">新增</a-button>
        </a-row>
        <br/>
        <a-table
                size="small"
                bordered
                rowKey="id"
                :columns="columns"
                :pagination="false"
                :loading="loading"
                :dataSource="dataList">
            <span slot="serial" slot-scope="text, record, index">
                {{ index + 1 }}
            </span>
            <span slot="action" slot-scope="text">
                <a @click="addOrEdite(text)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm
                        title="是否同意删除？"
                        @confirm="remove(text.id)"
                        okText="是"
                        cancelText="否"
                        placement="topRight"
                >
                    <a class="txt-danger" href="javascript:;">删除</a>
                </a-popconfirm>
            </span>
        </a-table>
        <br/>
        <a-pagination
                style="float: right"
                show-size-changer
                :default-current="pagination.currentPage"
                :total="pagination.total"
                @showSizeChange="onShowSizeChange"
        />
        <form-modal
                ref="FormModal"
                :visible="visible"
                @close="handcanle"
                @confirm="handleConfirm"
        />
    </div>
</template>

<script>
    import {columns} from "./module";
    import FormModal from "./module/formModal"
    import {list,del} from '@/api/model'
    export default {
        components:{FormModal},
        data(){
            return {
                visible: false,
                columns,
                formLayout:'inline',
                loading: false,
                searchForm:{},
                pagination: {},
                dataList:[]
            }
        },
        created() {
            this.loadList()
        },
        methods:{
            search() {
                this.loadList()
            },
            reset(){
                this.searchForm ={}
                this.load()
            },
            loadList() {
                const { page = 1, pageSize = 10 } = this.pagination
                const currentPage = page
                const params = {
                    ...this.searchForm,
                    currentPage,
                    pagesize: pageSize
                }
                list(params).then((res)=>{
                    this.dataList = res.records
                    const pagination = { ...this.pagination }
                    pagination.total = Number(res.total)
                    pagination.pageSize = Number(10)
                    this.pagination = pagination
                })
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
            },
            addOrEdite(row) {
                this.visible = true
                if(row){
                    this.$refs.FormModal.setFormValues(row)
                }
            },
            handcanle() {
                this.visible = false
            },
            handleConfirm() {
                this.loadList()
            },
            remove(id) {
                del({id:id}).then((res) =>{
                    this.loadList()
                })
            }
        }
    }
</script>

<style scoped>
</style>