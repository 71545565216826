<template>
    <a-modal
            :title="id?'参数修改':'参数新增'"
            :visible="visible"
            width="30%"
            :confirm-loading="confirmLoading"
            ok-text="确认"
            cancel-text="取消"
            @ok="handleConfirm"
            @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item label="电梯类型" v-bind="formItemLayout">
                <a-radio-group v-model="form.resource">
                    <a-radio value="1">
                        Sponsor
                    </a-radio>
                    <a-radio value="2">
                        Venue
                    </a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="电梯类型" v-bind="formItemLayout">
                <a-select v-decorator="['elevatorType',{rules: [{ required: true, message: '必选，请选择电梯类型' }]}]"
                          placeholder="请选择电梯类型" allowClear>
                    <a-select-option v-for="item in elevator" :key="item.key"
                                     :value="item.key">{{item.val}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="分类" v-bind="formItemLayout">
                <a-select v-decorator="['categoryId']"
                          placeholder="请选择分类" allowClear>
                    <a-select-option v-for="it in category" :key="it.key"
                                     :value="it.key">{{it.val}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="名称" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入名称"
                        v-decorator="['名称',
            {rules: [{ required: true,max:20, message: '名称必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="价格" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入价格"
                        v-decorator="['price',
            {rules: [{ required: true,max:20, message: '价格必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="图片" v-bind="formItemLayout">
                <a-upload
                        name="avatar"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            Upload
                        </div>
                    </div>
                </a-upload>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import {add,update} from '@/api/model'
    export default {
        props:['visible'],
        data(){
            return {
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 6
                    },
                    wrapperCol: {
                        span: 18
                    }
                },
                maxLength:1000,
                confirmLoading: false,
                elevator:[
                    {val:'客梯',key:0},
                    {val:'货梯',key:1},
                    {val:'扶梯',key:2},
                ],
                category:[
                    {val:'功能参数',key:0},
                    {val:'装潢参数',key:1},
                ],
                id:'',
                loading: false,
                imageUrl: '',
            }
        },
        computed: {
            show: {
                get: function (){
                    return this.visible;
                },
                set: function () {}
            }
        },
        watch:{
            visible(val){
                if(val) {
                }
            }
        },
        methods:{
            handleChange(info) {
                console.log(info)
                if (info.file.status === 'uploading') {
                    this.loading = true;
                    return;
                }
                if (info.file.status === 'done') {
                    // Get this url from response in real world.
                    getBase64(info.file.originFileObj, imageUrl => {
                        this.imageUrl = imageUrl;
                        this.loading = false;
                    });
                }
            },
            beforeUpload(file) {
                console.log(file)
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    this.$message.error('You can only upload JPG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('Image must smaller than 2MB!');
                }
                return isJpgOrPng && isLt2M;
            },
            setFormValues(record) {
                Object.keys(record).forEach(key => {
                    this.form.getFieldDecorator(key);
                    let obj = {};
                    if(key == "id") {
                        this.id = record[key]
                    }else {
                        obj[key] = record[key];
                        this.form.setFieldsValue(obj);
                    }
                });
            },
            handleConfirm(e) {
                this.confirmLoading = true;
                this.form.validateFields(async (err, values) => {
                    this.confirmLoading = false;
                    if(!err) {
                        console.log(values)
                        if (!this.id) {
                            add(values).then((res) => {
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }else {
                            values.id = this.id
                            update(values).then((res) => {
                                this.id = ''
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }
                    }
                });
            },
            handleCancel() {
                this.$emit('close')
                this.departmentSn=''
                this.form.resetFields()
            }
        }
    }
</script>

<style scoped>

    .avatar-uploader > .ant-upload {
        width: 128px;
        height: 128px;
    }
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

</style>